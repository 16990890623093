.report_container {

    width: 100%;
    // display: flex;
    justify-content: space-between;
    background-color: white;
    // padding: 1rem 2rem;
    border-radius: 5px;
    border: 2px solid #e8e8e8;
    position: relative;
    z-index: -2;
    padding: 0rem .75rem;
    color: black;

    &_main_header {
        font-size: 22px;
        font-weight: 600;

        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        // border-bottom: 2px solid #e8e8e8;
        width: 94%;
        margin: 0 auto;
        padding-right:.75rem ;

        border-bottom: 2px solid #e8e8e8;
        margin: 0 auto;
        &_common {
            font-size: 20px;
            font-weight: 600;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            width: 94%;
            margin: 0 auto;
   color: black;

        }
     
    }


    &_chart_header {
        font-weight: bold;
        font-size: 14px;

    }


    &>canvas{
        background-color: red;
        color:red !important
    }

}

.filters{
    margin-top:2rem;
    
}

.filters_charts{
    display: flex;
    justify-content: space-between;
    margin-bottom:2rem;

}

.checkbox_group{
    color: black;
}