.back-to-top {
  position: fixed;
  bottom: 100px;
  right: 44px;
  background-color: #ffffff;
  cursor: pointer;
  border: none;
  z-index: 2;
}

.back-to-top:hover {
  color: #f72b71;
}

@media screen and (max-width: 768px) {
  .back-to-top {
    position: fixed;
    bottom: 100px;
    right: 24px;
    font-size: 60px;
    background-color: #ffffff;
    color: black;
    cursor: pointer;
    border-radius: 100px;
    border: none;
    box-shadow: 0 5px 10px #ccc;
  }

  .back-to-top:hover {
    color: #f72b71;
  }
}
