.table-header__cell {
  display: flex;
}

.sticky-col {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  @media screen and (max-width: 600px) {
    position: none;
    top: 0;
    left: 0;
    background-color: white;
    position: relative;
  }
}

.common_tab_and_table {
  width: 100%;
}
.common_sidebar_and_table {
  display: flex;
  // margin-top: 100px;
  justify-content: center;

  &_sidebar {
    margin-top: 80px;
    @media screen and (max-width: 820px) {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;
  }
}
