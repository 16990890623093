@use './animations';
@use './mixins' as *;
@use './variables' as *;
@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

* {
  margin: 0;
  padding: 0;
  margin: 0;
  font-family: 'Mulish', 'Open Sans', sans-serif;
}
a {
  text-decoration: none;
}
/* Hide scrollbar for all browsers */
body {
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
  overflow: -moz-scrollbars-none !important; /* Legacy for older Firefox versions */
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
  display:none !important; /* Chrome, Safari, and Edge */
}
#scrollTopButton.show {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

#scrollTopButton.hide {
  opacity: 0;
  pointer-events: none
}

ul,
ol,
li {
  padding-inline-start: 0;
  margin-block-end: 0;
  list-style-type: none;
}

p {
  margin-block-end: 0;
}

// header
.custom-datepicker .MuiPickersDay-dayDisabled {
  color: white;
  /* Set the color to white for disabled months */
}

.footer {
  width: 100%;
  background-color: #f8f8f8;
}

input {
  padding: 3px;
  color: var(--colors-dark-100);
  width: 5em;
  margin: 2px;

  outline-width: 2px;
  outline-style: solid;
  outline-color: transparent;
  border-radius: var(--radius-s);

  border: 1px solid var(--colors-light-300);

  transition: 150ms outline-color ease, 150ms border-color ease;

  &:disabled {
    background-color: #f2f4f6;
  }

  &:focus {
    outline-color: var(--colors-primary);
    border-color: transparent;
  }
}
.card-container:hover .animation-overlay {
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.5); /* Change to desired color */
  }
  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.animation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 242, 242, 0.2); /* Initial color */
  pointer-events: none;
  z-index: 1;
}

.card-container:hover .animation-overlay {
  animation: pulse 3.5s infinite;
}
//Firm background
.firm_background_form_grid {
  justify-content: center;
  // border: 2px solid var(--colors-light-200);
  margin: 1rem 0 !important;
  // padding: 20px 0px 40px 0px;
}

.firm_background_form_grid .firmbackground_label_grid {
  // height: 70%;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: black;
  padding: 0px;
}

.firm_background_form_grid .firmbackgroudn_form_label {
  font-weight: 600;
  color: black;
}
#forecast-label-error {
  padding-bottom: 40px;
}

.css-1wc848c-MuiFormHelperText-root {
  color: red !important;
}

.datePickerError .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.datePickerError label {
  color: red !important;
}

#firmbackground-cord-body {
  // background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 1rem;
  border: 2px solid var(--colors-light-200);

  & hr {
    margin: 0.2rem 0px;
  }
}

#firmbackground-container {
  width: 100%;
  font-weight: bold;
  color: white;
  background: white;
  margin: 0 auto;
}

.justify-content-end {
  margin: 10px 0px;
  color: black;
  font-size: 16px;
  font-weight: 400px;
}

.MuiPickersFadeTransitionGroup-root
  .css-31ca4x-MuiPickersFadeTransitionGroup-root {
  background-color: red !important;
}

/* MUI TextField ve FormControl root sınıflarına Tailwind CSS stilini !important ile uygula */
.MuiFormControl-root .MuiTextField-root,
.MuiTextField-root,
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border-radius: 0.375rem !important; /* Tailwind rounded-lg */
  font-size: 1rem !important; /* Tailwind text-base */
  color: #111827 !important; /* Tailwind text-gray-900 */
  width: 100% !important; /* Tailwind w-full */
  display: block !important; /* Tailwind block */
}

/* Input boyutunu büyütmek için padding ve font-size ekleyelim */
.MuiFormControl-root .MuiTextField-root input,
.MuiTextField-root input,
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root input {
  font-size: 0.875rem !important; /* Small font size */
  padding: 0.65rem .5rem !important; /* py-3 */
  background-color: #F9FAFB !important;
  cursor:pointer !important;
  border:none !important

}


/* Odaklandığında mavi sınır ve ışık efekti */
.MuiFormControl-root .MuiTextField-root:focus,
.MuiTextField-root:focus,
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:focus {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5) !important; /* Tailwind focus:ring-blue-500 */
}

.MuiInputBase-root, .css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 6px !important;
  width: 100% !important;
}
/* Karanlık modda focus durumunda */
.MuiFormControl-root .MuiTextField-root:focus,
.MuiTextField-root:focus,
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:focus {
  border-color: #3b82f6 !important; /* Tailwind dark:focus:ring-blue-500 */
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5) !important; /* Tailwind dark:focus:ring-blue-500 */
}


.step9 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
 .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px !important; /* Smaller padding for compact height */
  font-size: 0.875rem !important; /* Small font size */
  border-radius: 4px !important; /* Rounded corners */
  height: 32px !important; /* Shorter height */
  width: 100% !important; /* Full width */

  outline: none !important; /* Remove outline */
  box-sizing: border-box !important; /* Ensure padding is included in height/width */
  cursor: pointer !important;

}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-left: none !important;  /* Sol kenara beyaz sınır */
  border-right: none !important;  /* Sağ kenara beyaz sınır */
  border-top: none !important;  /* Üst kenarı kaldır */
  border-bottom: none !important;  /* Alt kenarı kaldır */
  
}

[type=text]:focus, [type=email]:focus, [type=url]:focus, [type=password]:focus, [type=number]:focus, [type=month]:focus, [type=time]:focus, [type=week]:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-ring-color: none !important;
}

.custom-date-input:focus {
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.3); /* Blue focus ring */
}

/* Responsive styles */
@media (max-width: 768px) {
  .custom-date-input {
    font-size: 0.75rem; /* Smaller font size for mobile */
    height: 28px; /* Even shorter height for mobile */
  }
}
// Analysis Styles

.financial-main {
  margin-left: 10px;
  min-height: 360px;
}

.tableBackground {
  background-color: white;
  padding: 1em;
  border-radius: 1em;
  border: 2px solid var(--colors-light-100);
  overflow: auto;
}

.main-progress-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &-content {
    display: flex;
    justify-content: space-around;
    padding: 0.5em 0;
    border-radius: 5px;
    // border: 2px solid #e8e8e8;
    width: 33%;
    margin:10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

    font-size: 14px;

    &-table {
      & hr {
        margin: 0.2rem 0;
        background-color: var(--colors-light-400);
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        & th {
          text-align: center;
        }
      }

      &-body {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0.5rem;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }

        & tr {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          & td {
            text-align: center;
            margin-bottom: 0.25rem;
          }
        }
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      width: 40%;
      margin: 0 auto;
    }
  }
}

.main-progress-container > table > thead {
  border-bottom: 2px solid #022069;
  color: #022069;
}

thead {
  color: #022069;
}

// MyFirm Styles

.myfirm-button {
  background-color: $button-border-color !important;
  color: white;
  margin: 0.4em;
}

#header-search-container-mobile {
  display: none !important;
}

.analysis-table-header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  min-width: 100%;

  &-sub {
    border-radius: 5px;
    max-width: 100%;
    min-width: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    // flex-direction: column;
    justify-content: space-between;
    gap: 0.75rem;
    margin: 0.1em 0;

    font-size: 14px;

    &-text {
      font-weight: bold;

      &-custom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: auto;
        background-color: #0d6efd;
        color: #012169;
        border-radius: 5px;
        padding: 5px 10px;
        margin-bottom: 10px;
        color: white;
        font-weight: bold;

        & > span {
          margin-right: 300px;
        }

        &-button {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          padding: 1rem;

          gap: 0.25rem;
          margin: 0 auto;

          @media screen and (max-width: 768px) {
            & {
              flex-direction: column;
            }
          }

          & button {
            font-size: 16px;

            @media screen and (max-width: 768px) {
              & {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &-paranthesis {
      margin: 0 0.5em;

      &-sub {
        color: #b73081;
        font-weight: bold;

        &-left {
          color: white;
        }
      }
    }
  }
}

.tab {
  display: flex;
  flex-wrap: nowrap;

  &.active > div {
    background-color: $button-border-color;
    color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
}

.tab > div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $header-color;
  color: #e8e8e8;
  width: 150px;
  margin: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  padding: 0.2em 0.2em;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.common-tab {
  @media screen and (max-width: 600px) {
    width: 33%;
    font-size: 0.75rem;
    margin: 0.25rem;
  }
}
.custom-header-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

td {
  vertical-align: middle;
}

#dropdown-language {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 4px;
  margin-left: 18px !important;
  margin-bottom: 10px !important;

  // @media screen and (max-height: 1024px) {
  //   margin-left: 18px !important;
  //   margin-bottom: 10px !important;
  // }
  // @media screen and (max-height: 769px) {
  //   margin-left: 18px !important;
  //   margin-bottom: 10px !important;
  // }
  // @media screen and (max-height: 500px) {
  //   margin-left: 18px !important;
  //   margin-bottom: 10px !important;
  // }
  & a {
    color: white;

    .ml-1 {
      color: black;
    }
  }

  // & span {
  //   margin-left: 5px;
  // }
}

#language-dropdown-a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-input {
  width: 100%;
}

#as {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #012169;
  color: #e8e8e8;
  width: 150px;
  margin: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  padding: 0.2em 0.2em;
  border-radius: 5px;
}

.tabs-container {
  margin-left: auto;

  // @media screen and (max-width: 600px) {
  //   justify-content: center;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   position: sticky;
  //   top: 0;
  //   left: 0;
  //   background-color: white;
  // }

  // & .tabs-container {
  //   @media screen and (max-width: 768px) {
  //     max-width: 70%;
  //   }
  // }
}
.d-flex {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.d-flex,
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 768px) {
    & > button {
      font-size: 14px !important;
      width: 25%;
    }
  }
  @media screen and (max-width: 500px) {
    & > button {
      font-size: 12px !important;
      width: 25%;
    }
  }
}

#tabs-container {
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  margin-bottom: 10px;
  border: 1px solid var(--colors-light-200);
  border-radius: var(--radius-m);
  padding: 0.5rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 600px) {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
  }

  & .tab {
    @media screen and (max-width: 768px) {
      & > div {
        font-size: 14px !important;
      }
    }
  }
}

.financial-main-table {
  color: black !important;

  &-tbody {
    & tr {
      & td {
        color: black !important;
      }
    }
  }

  & th:not(:first-child) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    & td,
    & th {
      font-size: 12px;
      margin-right: 10px;
    }

    @media screen and (max-width: 600px) {
      &-tr {
        display: flex;
        margin-left: 80px;

        & th {
          margin-left: 2px;
        }
      }
    }
  }

  &-input {
    text-align: right !important;

    @media screen and (max-width: 540px) {
      & {
        width: 60px;
      }
    }
  }

  &-fixed-row {
    width: 300px;
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 10px;

    @media screen and (max-width: 992px) {
      & {
        width: 180px;
        display: block;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: white;
        width: 80px;
      }
    }

    @media screen and (max-width: 540px) {
      & {
        width: 80px;
        display: block;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
        position: absolute;
        z-index: 1;
        left: 16px;
        margin-top: 9px;
      }

      &-title {
                -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #012169;
        position: absolute;
        z-index: 1;
        left: 16px;
        margin-top: 4px;
        background-color: white;
        max-width: 80px;
        min-width: 80px;
        height: 80px;
      }
    }

    &-income-text {
      color: #012169;
    }
  }

  &-tbody {
    @media screen and (max-width: 540px) {
      margin-left: 80px !important;
      display: grid;
    }
  }
}

.rtable {
  margin-left: 10px;
}

//navbar
.navbar-dark .navbar-nav .nav-link {
  color: white;
  font-size: 1.25rem;
}
.apexcharts-menu {
  background-color: white !important; /* Açılır menü arka planı beyaz */
}

.apexcharts-menu-item {
  color: black !important; /* Menü metinleri siyah */
  font-weight: bold; /* Kalın yazı */
}

.apexcharts-menu-item:hover {
  background-color: #f0f0f0 !important; /* Hover rengi */
}


.header {
  border-bottom: 1px solid #ddd;
  background: linear-gradient(to left, #01277c, #0062d4, #107eff);
  .container {
    display: flex;
    align-items: center;
  }

  &-menu {
    display: flex;
    align-items: center;

    a {
      color: $color-navy;
      text-decoration: none;
      &:focus,
      &:hover {
        color: white;
      }
    }

    &-center {
      display: flex;

      a {
        display: inline-flex;
        align-items: center;
        padding-left: $gutter;
        padding-right: $gutter;
        height: $finger-size;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.container {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

.header-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &-center {
    display: flex;
    justify-content: space-between;

    &-dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 20px;

      & #dropdown-item-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background-color: #012169;
        border: #012169;
        box-shadow: none;
        border-radius: 0px;
        font-weight: bold;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: $gutter;
        padding-right: $gutter;
        height: $finger-size;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: space-between;

    a {
      display: inline-flex;
      align-items: center;
      padding-left: $gutter;
      padding-right: $gutter;
      height: $finger-size;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &-search-form {
    width: 30%;

    &-content {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      &-input {
        width: 100%;
        border-right: none;
        padding: 5px;
        height: 20px;
        outline: none;
        color: #9dbfaf;
        margin: 0px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        height: 40px;

        &:focus {
          color: $button-border-color;
        }
      }

      &-button {
        width: 50px;
        height: 40px;
        border: 1px solid $button-border-color;
        background-color: $button-border-color;
        text-align: center;
        color: #fff !important;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    padding: 0px;

    span {
      color: #f73681;
    }
  }
}

.analysis_button {
  transition: 0.5s;

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    background-color: $header-color;
    transition: 0.5s;
  }
}

.cc-unoo {
  transition: 0.5s !important;

  &:hover {
    transition: 0.5s !important;
    transform: scale(1.1);
  }
}

//login register

.main-login {
  @include position();
  margin-top: 8vh;
}

%login-item {
  min-width: 250px;
  font-size: $default-font-size;

  margin: 0.4em auto;
  padding: 0.8em;
  width: 90%;
  box-sizing: border-box;
}

.login {
  &-fieldset {
    animation: login-fadein $animation-time ease-in-out forwards;
    animation-delay: $animation-time;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding: 4em 2em;
    opacity: 0;
    font-size: $default-font-size;

    &-field {
      @extend %login-item;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
      @include border($size: 1px, $color: #f2f2f2);
      font-size: $default-font-size;
    }

    &-submit {
      @extend %login-item;
      background-color: $equlion-color;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
      color: #fff;
      font-weight: bold;
      @include border($radius: 3px);
    }
  }
}

.signup {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  @include position(relative);
  @include border($radius: 5px);

  &-sides {
    width: 40%;
    height: 94%;
    margin: 0 auto;
    @include position(
      $type: absolute,
      $top: 0,
      $left: 0,
      $right: 0,
      $bottom: 0
    );
    @include border($radius: 5px);

    line {
      fill: none;
      stroke: $equlion-color;
      stroke-dasharray: 100%;
      stroke-dashoffset: 100%;
      stroke-linecap: round;
      stroke-width: 3;
      animation: login-border-animation $animation-time forwards;

      &.first {
        animation-delay: $animation-time;
      }

      &.second {
        animation-delay: $animation-time + 0.25s;
      }

      &.third {
        animation-delay: $animation-time * 2;
      }
    }
  }
}

.update {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  @include position(relative);
  @include border($radius: 5px);

  &-sides {
    // @include square-size(720px);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    @include position(
      $type: absolute,
      $top: 0,
      $left: 0,
      $right: 0,
      $bottom: 0
    );
    @include border($radius: 5px);
    line {
      fill: none;
      stroke: $equlion-color;
      stroke-dasharray: 100%;
      stroke-dashoffset: 100%;
      stroke-linecap: round;
      stroke-width: 3;
      animation: login-border-animation $animation-time forwards;
      &.first {
        animation-delay: $animation-time;
      }

      &.second {
        animation-delay: $animation-time + 0.25s;
      }
      &.third {
        animation-delay: $animation-time * 2;
      }
    }
  }
}

.button-equlion {
  animation: button-equlion-animation $animation-time forwards;
  background-image: url('https://image.flaticon.com/icons/svg/145/145812.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: $equlion-color;
  margin: auto;
  z-index: 1;
  transition: box-shadow 0.5s;
  font-size: $default-font-size;

  @include border($size: 3px, $color: #fff, $radius: 50%);
  @include position($type: absolute, $left: 0, $right: 0);
  @include size($width: 6em, $height: 6em);

  &:hover {
    box-shadow: 0px 1px 1px 1px #ccc;
  }
}

.error_input_message {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: end;
}

.register-error-message {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  background-color: #f72d74;
  font-weight: bold;
}

.loadingSpinnerBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinnerContainer {
  width: 128px;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loadingSpinner {
  width: 128px;
  height: 128px;
  animation: 1s spin ease infinite;
  border: 8px solid;
  border-color: #f73681 transparent white transparent;
  border-radius: 50%;
  position: relative;

  & + .inner {
    animation: 1s logoAnimation ease infinite alternate;
    position: absolute;
  }
  .common-content-edit {
    display: flex;
  }
}

.reset_password_email_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  padding: 2em;
  box-sizing: border-box;
  @include position(relative);
  @include border($radius: 5px);

  &_form {
    margin-left: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    &_input {
      height: 2.4em;
      width: 100%;
      margin-left: 10px;
    }

    & > button {
      margin-left: 20px;
    }
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_input {
        margin-left: 0;
        margin-top: 10px;
      }
      & > button {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1em;
  }
}

// Mobile Fixing
@media only screen and (max-width: $md) {
  #header-search-container {
    display: none !important;
  }

  #responsive-navbar-nav {
    padding-left: 0 !important;
    align-items: center !important;
  }
  .navbar-nav {
    padding-left: 0 !important;
    align-items: center !important;
  }

  #header-search-container-mobile {
    display: flex !important;
  }

  #table-progress {
    width: 85%;
  }

  .firmAnalysis-table-header-container {
    flex-direction: column;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  #tabs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1em auto;
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
  }

  .tab > div {
    padding: 0em 0em;
    font-size: 1em;
    margin: 2px;
    width: 100px;
  }

  .financial-main-table {
    &-input {
      text-align: right !important;
    }
  }

  #firmbackground-container {
    width: 100%;
  }

  #language-dropdown-a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
    color: black;
  }

  .buttonlink {
    margin: 10px 0px !important;
    width: 250px;
    height: 300px;
  }

  #firmbackground-cord-body {
    padding: 0px;

    & .row {
      flex-direction: column !important;

      & .col-6 {
        width: 100% !important;

        & label,
        & input,
        & select {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  #tabs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1em auto;
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

iframe {
  pointer-events: none !important;
}

//register login

@media screen and (min-width: 768px) {
  .signup {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    @include position(relative);
    @include border($radius: 5px);

    &-sides {
      // @include square-size(720px);
      width: 80%;
      height: 110%;
      margin: 0 auto;
      @include position(
        $type: absolute,
        $top: 0,
        $left: 0,
        $right: 0,
        $bottom: 0
      );
      @include border($radius: 5px);

      line {
        fill: none;
        stroke: $equlion-color;
        stroke-dasharray: 100%;
        stroke-dashoffset: 100%;
        stroke-linecap: round;
        stroke-width: 3;
        animation: login-border-animation $animation-time forwards;

        &.first {
          animation-delay: $animation-time;
        }

        &.second {
          animation-delay: $animation-time + 0.25s;
        }

        &.third {
          animation-delay: $animation-time * 2;
        }
      }
    }
  }
}

@media screen and (min-width: 820px) {
  .signup {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    @include position(relative);
    @include border($radius: 5px);

    &-sides {
      // @include square-size(720px);
      width: 70%;
      height: 110%;
      margin: 0 auto;
      @include position(
        $type: absolute,
        $top: 0,
        $left: 0,
        $right: 0,
        $bottom: 0
      );
      @include border($radius: 5px);

      line {
        fill: none;
        stroke: $equlion-color;
        stroke-dasharray: 100%;
        stroke-dashoffset: 100%;
        stroke-linecap: round;
        stroke-width: 3;
        animation: login-border-animation $animation-time forwards;

        &.first {
          animation-delay: $animation-time;
        }

        &.second {
          animation-delay: $animation-time + 0.25s;
        }

        &.third {
          animation-delay: $animation-time * 2;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .signup {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    @include position(relative);
    @include border($radius: 5px);

    &-sides {
      // @include square-size(720px);
      width: 100%;
      height: 100%;
      margin: 0 auto;
      @include position(
        $type: absolute,
        $top: 0,
        $left: 0,
        $right: 0,
        $bottom: 0
      );
      @include border($radius: 5px);

      line {
        fill: none;
        stroke: $equlion-color;
        stroke-dasharray: 100%;
        stroke-dashoffset: 100%;
        stroke-linecap: round;
        stroke-width: 3;
        animation: login-border-animation $animation-time forwards;

        &.first {
          animation-delay: $animation-time;
        }

        &.second {
          animation-delay: $animation-time + 0.25s;
        }

        &.third {
          animation-delay: $animation-time * 2;
        }
      }
    }
  }

  .css-1b3l6lk-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 50% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }

  .css-178yklu {
    margin-top: 0px !important;
  }

  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-top: 8px !important;
  }

  .css-1mhjvbd-MuiButtonBase-root-MuiButton-root {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
  }

  .css-16g5rpk-MuiGrid-root {
    margin-bottom: 8px !important;
  }
}

//metarial ui fixes

.auth_form {
  width: 600px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
  }
}

.login_common_text {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.common_navbar {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--colors-light-200);
  border-radius: var(--radius-m);
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  width: 20%;
}

.MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
  margin: 0px !important;
  border-radius: var(--radius-xs) !important;
}

.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: var(--colors-header) !important;
  color: white !important;
  box-shadow: 0px !important;
  border-radius: var(--radius-xs) !important;
  display: flex;
  padding: 0px !important;
}

.css-v84d5j-MuiSvgIcon-root {
  color: white !important;
}

.css-1ld1lzp-MuiPaper-root-MuiAccordion-root {
  border: none !important;
  border-radius: var(--radius-xs) !important;
  max-width: 200px !important;
}

.css-1betqn-MuiAccordionSummary-content {
  padding: 0.625rem 1.375rem 0.625rem 8px !important;
  font-size: 14px !important;
  border-radius: var(--radius-xs) !important;

  &:hover {
    background-color: #042d8c;
    border-radius: var(--radius-xs) !important;
  }
}

.css-ahj2mt-MuiTypography-root {
  font-size: 14px !important;
}

.css-y6rp3m-MuiButton-startIcon {
  margin-right: 0px !important;
  margin-left: -2px !important;
}

.css-11qr2p8-MuiButtonBase-root-MuiButton-root {
  justify-content: space-between !important;
  background-color: #0d6efd !important;
}

.mx-auto {
  width: 100%;
  justify-content: space-around !important;
  align-items: center !important;
  @media screen and (max-width: 769px) {
    align-items: flex-start !important;
  }
}

.css-1uh3lx7-MuiAccordionDetails-root {
  padding: 4px !important;
}

// .MuiPaper-root {
//   // width: 200px !important;
//   // height: 100% !important;
//   // margin-top: 60px;

//   @media screen and (max-width: 820px) {
//     margin-top: 0px;
//   }
// }

.css-1hskriy {
  width: 200px !important;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  // width: 200px !important;
}

.css-10hburv-MuiTypography-root {
  font-size: 0.8rem !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.css-1nxmd3h-MuiListItem-root {
  align-items: center !important;
}

// .react-joyride__overlay{
//   background-color: #006ac8 !important;
// }

.css-1pduc5x-MuiStack-root > .MuiTextField-root {
  // min-width: 150px;
  width: 160px;
}
#end-date {
  @media screen and (max-width: 769px) {
    width: 100% !important;
  }
}
.css-1pduc5x-MuiStack-root {
  overflow: hidden !important;
}

.css-fldub7-MuiPickersMonth-root .css-bw88rr-MuiPickersMonth-monthButton.Mui-d {
  color: white !important;
}

.css-bw88rr-MuiPickersMonth-monthButton.Mui-disabled {
  opacity: 0.2 !important;
}

.css-bw88rr-MuiPickersMonth-monthButton {
  font-weight: 600 !important;
}

.common-canvas canvas {
  border-top-left-radius: 32px;
}
.color-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    white
  ); /* Initial gradient with three colors */
  background-size: 200% 100%;
  animation: mixColors 34s infinite linear; /* Adjust the time for each color display */
}

@keyframes mixColors {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 33.33% 0; /* Transition to the next color */
  }
  50% {
    background-position: 66.67% 0; /* Transition to another color */
  }
  75% {
    background-position: 100% 0; /* Transition to the last color */
  }
  100% {
    background-position: 0 0; /* Return to the initial color for a smooth loop */
  }
}

.home-box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 20px;

  &-video {
    min-width: 50%;
  }

  > .text-box {
    width: 100%;
  }
}
#chat-box {
  width: 80%;
  height: 70vh;
}

.chat-box {
  height: 300px;
  min-width: 50%;
  margin: 0 auto;
  background: white;
  border: 2px solid black;
}

.message {
  margin: 10px;
  border-radius: 5px;
}

.received {
  background-color: #f1f0f0;
  text-align: left;
}

.sent {
  background-color: #007bff;
  color: #fff;
  text-align: right;
}

.input-group {
  margin-top: 10px;
}

@keyframes gradient {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}

.home-link-container {
  display: flex;
  width: 80%;
  max-height: 300px;

  &-col {
    display: flex;
    align-items: flex-end;
    color: white;
  }
  &-img {
    max-width: 100px; /* Max width */
    max-height: 120px; /* Max height */
    padding: 20px;
  }

  &-image {
    min-height: 120px;
  }
  &-header {
    max-height: 100px;
  }
  &-text {
    height: 100%;
  }
}
@keyframes backgroundColorChange {
  0% {
    background-color: #01277c; /* Starting color */
  }
  50% {
    background-color: #0062d4; /* Midway color */
  }
  100% {
    background-color: #107eff; /* Ending color */
  }
}

.box {
  cursor: pointer;
  flex: 1;
  margin: 10px;
  margin-top: 60px;
  text-align: center;
  width: 100%;
  min-height: 300px;
  border-radius: 12px;

  animation: backgroundColorChange 4s infinite alternate; /* Apply the animation */
}
.box h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.box p {
  min-height: 150px; /* Set the desired fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 50px; /* Set the desired fixed height */
}
.box:hover {
  background-color: rgba(1, 39, 124, 0.5); /* Lower the background opacity */
  animation: hoverAnimation 2s ease infinite; /* Add an animation */
}

@keyframes hoverAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

#nav-logo {
  font-size: 1.5rem;
  color: #fff;
}
.nav-logo-3 {
  font-size: 1.5rem;
  color: #fff !important;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  background-color: #1b1a14;
}

#home-header-box {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    width: 100% !important;
  }

  @media screen and (max-width: 369px) {
    width: 110% !important;
  }
}
.home-header-box-container {
  @media screen and (max-width: 360px) {
    width: 100% !important;
    // background-color: red;
    // background-color: red;
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }
    @media screen and (max-width: 769px) {
    width: 100% !important;
    // background-color: red;
    // background-color: red;
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
}

.home-toolbar {
  @media screen and (max-width:369px) {
    width: 100% !important;
  }
  @media screen and (max-width: 769px) {
    width: 100% !important;
  }
  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
}

.start_now_button {
  max-width: 30%;
  margin: 0 auto !important;

  @media screen and (max-width:769px) {
    margin: 0 auto !important;
    margin-bottom: 2rem !important;
  }
  @media screen and (max-width:500px) {
    margin: 0 auto !important;
    margin-bottom: 2rem !important;
  }
}

// .home-toolbar-box {
//   @media screen and (max-width:369px) {
//     padding-top: 100px;
//   }
//   @media screen and (max-width:500px) {
//     padding-top: 80px;
//   }
// }

.home-video-box {
  @media screen and (max-width: 1024px) {
    margin-top: 0px !important;
    width: 100% !important;
    padding: 0px !important;
  }
  @media screen and (max-width:769px) {
    margin-top: 0px !important;
    width: 100% !important;
    padding: 0px !important;
  }
  @media screen and (max-width:500px) {
    margin-top: 0px !important;
    width: 100% !important;
    padding: 0px !important;
  }
}
.home-video-box-container-box {
  @media screen and (max-width:769px) {
    text-align: center !important;
  }
  @media screen and (max-width: 1024px) {
    text-align: center !important;
  }
}
.home-video-box,
.home-video-box-container-box,
.home-video-box-container-box-video {
  width: 100% !important;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
    margin: 0 auto;
  }
  @media screen and (max-width: 769px) {
    width: 100%;
    margin: 0 auto;
  }
}

.home-header-box-stack {
  width: 100% !important;
  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
  @media screen and (max-width:769px) {
    width: 100% !important;
    padding-top: 80px !important;
  }
  @media screen and (max-width:500px) {
    width: 100% !important;
    padding-top: 80px !important;
  }
}

.home-header-box-title > .two {
  font-size: 5rem !important;
  color: white !important;
  @media screen and (max-width: 1024px) {
    font-size: 4rem !important;
    flex-direction: column;
  }
  @media screen and (max-width: 820px) {
    font-size: 3.75rem !important;
    flex-direction: column;
  }
  @media screen and (max-width:769px) {
    font-size: 3.5rem !important;
    // flex-direction: column;
  }
  @media screen and (max-width:500px) {
    font-size: 1.5rem !important;
    flex-direction: row !important;
  }
  @media screen and (max-width:300px) {
    font-size: 0.5rem !important;
  }
}
.home-header-box-title > .one {
  @media screen and (max-width: 1024px) {
    color: #1976d2 !important;
    font-size: 3rem !important;
    flex-direction: row !important;

    // text-align: start;
  }
  @media screen and (max-width:769px) {
    font-size: 2rem !important;
    flex-direction: row !important;
  }
  @media screen and (max-width:500px) {
    font-size: 0.5rem !important;
  }
  @media screen and (max-width:300px) {
    font-size: 0.5rem !important;
  }
}

.home-header-box-title {
  font-size: 5rem !important;
  @media screen and (max-width: 1024px) {
    font-size: 4rem !important;
    flex-direction: column;
  }
  @media screen and (max-width: 820px) {
    font-size: 3.75rem !important;
    flex-direction: column;
  }
  @media screen and (max-width:769px) {
    font-size: 3.5rem !important;
    // flex-direction: column;
  }
  @media screen and (max-width:500px) {
    font-size: 1.5rem !important;
    flex-direction: row !important;
  }
  @media screen and (max-width:300px) {
    font-size: 0.5rem !important;
  }
}

.section {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  scroll-snap-align: start;
  @media screen and (max-width: 1024px) {
    height: auto;
    gap: 16px;
    flex-wrap: nowrap !important;
    min-height: 450px !important;
  }
  @media screen and (max-width:769px) {
    height: auto;
    gap: 80px;
    flex-wrap: nowrap !important;
    min-height: 520px !important;
  }
  @media screen and (max-width:600px) {
    height: auto;
    gap: 16px;
    flex-wrap: nowrap !important;
    min-height: 520px !important;
  }
}

.section:nth-child(2n) {
  flex-direction: row;
}

.section-title {
  font-size: 54px;
  font-weight: 900;
  margin-bottom: 32px;
  color: #1976d2;
  @media screen and (max-width: 1024px) {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 32px;
  }
  @media screen and (max-width:769px) {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 32px;
  }
  @media screen and (max-width:600px) {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 32px;
  }
}
.section-text {
  color: black;
  @media screen and (max-width: 1024px) {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 32px;
  }
  @media screen and (max-width:769px) {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 32px;
  }
  @media screen and (max-width:600px) {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 32px;
  }
}

.section-content {
  width: 40%;
  @media screen and (max-width: 1024px) {
    width: 40%;
  }
  @media screen and (max-width:769px) {
    width: 40%;
  }
}

.section-image {
  position: relative;
  width: 600px;
  height: 430px;
  perspective: 1000px;
  transition: 0.8s ease-in-out;
  @media screen and (max-width: 1024px) {
    width: 400px;
    height: 230px;
  }
  @media screen and (max-width:769px) {
    // width: 80%;
    // height: auto;
  }
}

.image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  transform: perspective(5000px) rotateY(-12deg) rotateX(-12deg);
  transition: transform 0.8s ease-in-out, filter 3s;
  transition-delay: 0.1s;
  background-size: cover;
  overflow: hidden;
}

.image::after {
  content: '';
  position: absolute;
  z-index: 2;
  inset: 0;
  background-size: cover;
  filter: grayscale(1);
}

.section:nth-child(2n) .image {
  transform: perspective(5000px) rotateY(12deg) rotateX(-12deg);
}

.anim .image {
  transform: perspective(5000px) rotateY(12deg) rotateX(-12deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.8);
}

.anim .image::after {
  animation: anim 2s forwards;
}

@keyframes anim {
  from {
    height: 100%;
  }

  to {
    height: 0%;
  }
}

.section:nth-child(2n).anim .image {
  transform: perspective(5000px) rotateY(-12deg) rotateX(-12deg);
}

.section:nth-child(1) .image,
.section:nth-child(1) .image::after {
  background-image: url('../images/forecast.png');
}

.section:nth-child(2) .image,
.section:nth-child(2) .image::after {
  background-image: url('../images/financialanalysis.png');
}

.section:nth-child(3) .image,
.section:nth-child(3) .image::after {
  background-image: url('../images/financialanalysisinput.png');
}

.section:nth-child(4) .image,
.section:nth-child(4) .image::after {
  background-image: url('../images/businessplan.png');
}

.section:nth-child(5) .image,
.section:nth-child(5) .image::after {
  background-image: url('../images/custom.png');
}

.section:nth-child(6) .image,
.section:nth-child(6) .image::after {
  background-image: url('../images/report.png');
}

.container-content-2 {
  width: 100%;
  background-image: linear-gradient(180deg, #cee5fd, #fff);
  -webkit-background-size: 100% 60%;
  background-size: 100% 60%;
  background-repeat: no-repeat;
}
.container-common {
  display: flex;
  width: 90%;
  margin: 0 auto;
  font-weight: 300;
  font-size: 6rem;
  line-height: 1.167;
  letter-spacing: -0.01562em;
  color: #1976d2;
}

.left-container {
  flex: 1;
  max-width: 45vw;
  padding-top: 100px;
  box-sizing: border-box;
  position: relative;
}

.left-box {
  position: sticky;
  top: 0%; /* Adjust this value to position it to your desired vertical center */
}

/* Style your content as needed */
.left-content {
  background-color: #f0f0f0;
  padding: 20px;
}

.content-item {
  margin: 10px 0;
}

.right-box {
  flex: 1;
}

// .right-box .content-item {
//   background-color: #5081f6;
//   height: 700px;
//   margin: 0 5px;
// }

.right-content-container {
  // background-color: #5081f6;
}

.left-box {
  height: 100vh; /* Set the height of the container to match the viewport */
  overflow: hidden; /* Hide the content that overflows the container */
  color: white;
  display: flex;
  font-size: 1.25rem;
  text-align: start;
  max-width: 45vw;
  padding: 0rem 2rem;
  position: sticky;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    font-size: 0.25rem;
    padding: 0rem;
  }
}

.left-box-header,
.left-box-header-2 {
  font-size: 1.75rem;
  margin-top: 3rem;
  @media screen and (max-width: 768px) {
    font-size: 0.75rem;
  }
}

.left-box-button {
  width: 30%;
  padding: 20px;
  border-radius: 9999px;
  margin-top: 2rem;
  background-color: transparent;
}

.left-box-button:hover {
  background-color: wheat; /* Change to your desired background color */
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.content-item {
  transition: opacity 0.5s ease, transform 0.5s ease; /* Add a transition for smooth reveal */
  background-color: #5081f6;
}

.right-content-container .content-item {
  z-index: 2;
}

.right-content-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.content-item {
  flex: 1;
  // max-width: 100%;
  box-sizing: border-box;
  // height: 400px;
  padding: 180px 0px;
}

.content-item img {
  max-width: 100%; /* Ensure the images shrink to fit the content-item */
  height: auto;
  // padding: 20px;
}

.left-box-content {
  margin-top: 3rem;
}

.box {
  display: flex;
  margin: 10px;
  border: 1px solid #ccc;
  width: 100%; /* Adjust the width as needed */
}

@import url('https://fonts.googleapis.com/css?family=Roboto:700');
@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;1,500&display=swap');

$cursor-duration: 10s;
$animation-timing: cubic-bezier(0.445, 0.05, 0.55, 0.95);

.animation-container {
  width: 100%;
  height: 100vw;
  max-height: 420px;
  max-width: 840px;
  margin: 40px auto;
  position: relative;
  animation: fadeIn 0.2s ease-out both;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://unitienda.net/demo/finalis/wp-content/uploads/2021/06/dots.png')
      30% 0 no-repeat;
    background-size: 18%;
    z-index: -1;
  }
  &:before {
    background-position: 80% 100%;
  }
  img {
    width: 100%;
    position: absolute;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  $circles: 1 10vw 10% 0% transparent #d6d3f8 bg-pulse 5s,
    2 15vw 0% 15% #d6d3f8 transparent bg-rotation 22s,
    3 5vw 52% 0% transparent #f6f7fe bg-pulse 3s,
    4 4vw 48% 5% #d6d3f8 transparent bg-rotation 23s,
    6 13vw 74% 32% #d6d3f8 transparent bg-rotation 23s,
    5 6vw 88% 50% transparent #ccecfc bg-pulse 4s;
  .circle {
    border-radius: 100%;
    position: absolute;
    @each $index, $size, $left, $top, $border, $bg, $animation,
      $duration in $circles
    {
      &:nth-child(#{$index}) {
        border: 2px dashed $border;
        background: $bg;
        left: $left;
        top: $top;
        width: $size;
        height: $size;
        animation: $animation $duration $animation-timing both infinite;
      }
    }
  }
  .cursor {
    width: 2vw;
    max-width: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    animation: cursor $cursor-duration $animation-timing both;
    animation-direction: alternate;
    fill: #333;
    .rotateCursor {
      transform: rotateY(180deg);
      transform-origin: center center;
    }
  }
  .home-hedaer-right {
    max-width: 50% !important;
    height: 100vh;
    display: flex;
  }
  .home-hedaer-right-content {
    width: 70%;
    margin: 0 auto;
    font-size: 18px;
    text-align: start;
  }
  .dashboard {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    display: block;
    margin: 0 auto;
    animation: scaleInCenter 1s ease-in-out;
  }
  svg {
    height: auto;
    position: absolute;
    user-drag: none;
    user-select: none;
  }
  .activity {
    width: 100%;
    max-height: 400px;
    top: 50%;
    right: 0%;
    width: 45%;
    animation: scaleInUp 0.7s ease-in-out 5.5s both;
    animation-direction: alternate;
    $sizesBars: 10, 20, 30, 40, 50, 60, 70, 80, 90, 110;
    $timeOffset: 0.07s;
    $i: 1;
    @each $sizesBar in $sizesBars {
      $delayBar: 6 + $i * $timeOffset;
      .bar.size-#{$sizesBar} {
        animation: barHeight-#{$sizesBar} 0.7s ease-in-out $delayBar both;
      }
      @keyframes barHeight-#{$sizesBar} {
        from {
          height: 0;
          transform: translateY(0px);
        }
        to {
          height: $sizesBar + px;
          transform: translateY(-$sizesBar + px);
        }
      }
      $i: 1 + $i;
    }
    .bubble {
      animation: showBubble 0.7s ease-in-out 7.5s both;
      transform-origin: center;
    }
    .days {
      $timeOffsetDays: 0.3s;
      animation: fadeIn 0.7s ease-in-out both;
      @for $i from 1 through 8 {
        &.day-#{$i} {
          animation-delay: 5.5 + $i * $timeOffsetDays !important;
        }
      }
    }
    .select {
      $timeOffsetDays: 0.3s;
      animation: fadeInUp 0.7s ease-in-out both;
      transform-origin: center;
      @for $i from 1 through 2 {
        &.sele-#{$i} {
          animation-delay: 5.5 + $i * $timeOffsetDays !important;
        }
      }
    }
    .title {
      animation: fadeInRight 1s ease-in-out 5.7s both;
    }
    .sub-title {
      animation: fadeInRight 1s ease-in-out 5.9s both;
    }
  }
  .menu {
    max-height: 520px;
    top: 0;
    left: 0;
    width: 30%;
    animation: scaleInUp 0.7s ease-in-out 1.5s both;
    animation-direction: alternate;
    .logo {
      animation: fadeInRight 0.7s ease-in-out 2s both;
    }
    .menu-item {
      $timeOffsetMenuItem: 0.3s;
      transform-origin: center;
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          animation: flipVertical 0.7s ease-in-out both;
          animation-delay: 1.5 + $i * $timeOffsetMenuItem !important;
        }
      }
    }
  }
  .compliance {
    top: 0;
    right: 10%;
    width: 30%;
    display: block;
    margin: 0 auto;
    animation: scaleInUp 0.7s ease-in-out 3.5s both;
    animation-direction: alternate;
    .bottom-link,
    .title {
      animation: fadeInRight 0.3s ease-in-out 4.5s both;
    }
    .text-step {
      animation: fadeInUp 0.3s ease-in-out 4.7s both;
    }
    .progress-bar {
      animation: fadeInUp 0.3s ease-in-out 4.9s both;
      .bar {
        width: 0;
        animation: progressBar 1s ease-in 5.3s both;
      }
    }
    .button-blue {
      animation: fadeInUp 0.3s ease-in-out 5.1s both;
    }
  }
}
@keyframes bg-pulse {
  50% {
    transform: scale(0.7) translatey(5%);
  }
}
@keyframes bg-rotation {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progressBar {
  to {
    width: 177px;
  }
}
@keyframes flipVertical {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@keyframes showBubble {
  from {
    transform: rotate(15deg) translatey(10px);
    opacity: 0;
  }
}

@keyframes scaleInCenter {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.2;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
@keyframes scaleInUp {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;

    transform: translatey(10px);
  }
  to {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;

    transform: translatex(-10px);
  }
  to {
    opacity: 1;
    transform: translatex(0);
  }
}
@keyframes cursor {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  10%,
  20% {
    left: 23%;
  }
  14% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.8);
  }
  16% {
    transform: scale(1);
  }
  30%,
  40% {
    top: 40%;
    left: 73%;
  }
  34% {
    transform: scale(1);
  }
  35% {
    transform: scale(0.8);
  }
  36% {
    transform: scale(1);
  }
  50%,
  60% {
    top: 75%;
    left: 73%;
  }
  51% {
    transform: scale(1);
  }
  52% {
    transform: scale(0.8);
  }
  53% {
    transform: scale(1);
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

// .home-container-text-and-video {
//   max-width: 100%;
//   // align-items: center;
//   // flex-direction: column;
//   min-height: 50vh;
//   background-color: #5081f6;
//   // background-color: blue;
//   padding: 4rem;
//   & .home-content-text-and-video {
//     display: flex;
//     justify-content: center;
//     margin: 0 auto;
//     width: 80%;
//     & > video {
//       min-width: 50%;
//       height: auto;
//       display: block;
//     }
//   }
// }

// .home-container-header {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// }

// .home-content-header {
//   display: flex;
//   justify-content: flex-end;
//   max-width: 1200px; /* Adjust the maximum width based on your design */
//   height: auto; /* Adjust the height based on your design or set it to auto */
// }

// .home-content-text {
//   flex: 1;
//   padding: 20px;
//   box-sizing: border-box;
// }

// .home-content-video {
//   flex: 1;
//   position: relative;
//   overflow: hidden;
//   border-radius: 1rem;
// }

// .background-image {
//   width: 100%;
//   height: auto;
// }

// .home-content-video .home-header-loader {
//   position: absolute;
//   top: 50%;
//   // left: 50%;
//   transform: translate(-50%, -50%);
// }

// .home-content-video video {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
// .home-container-header {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
// }

// .background-image {
//   width: 100%;
//   height: auto;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1; // Place the image behind other content
// }

// .home-content-header {
//   text-align: center;
// }

.area {
  position: relative;
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Ensure that the animation doesn't overflow */
}

.area video {
  position: relative;
  z-index: 1; /* Set a higher z-index to place it in front of the background */
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -250px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
@media screen and (max-width: 768px) {
  #homeHeaderVideoFinancial {
    width: 100%; /* Adjusted width for mobile devices */
    height: auto; /* Auto height for responsive design */
  }
}

.home-container-header {
  max-width: 80%;
  display: flex;
  justify-content: space-evenly;
  min-height: 60vh;

  &-text {
    min-width: 50%;
    color: #fff;
    padding: 1rem;
    font-size: 1rem;
  }
  &-video {
    object-fit: cover;
    border-radius: 20px;
    z-index: 1;
    min-width: 50%;
    &-in {
      width: 100%;
      height: auto;
      padding: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-container-header {
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
    &-text {
      // height: 20%;
      font-size: 0.75rem;
    }
  }
}

.home-container-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5081f6;
  &-animated-box > img {
    width: 50%;
  }
}
.home-container-2 > .text-box {
  color: white;
  width: 80%;
  padding: 2rem 0rem;
  height: 20%;
  font-size: 2rem;
}

.home-container-2 > .row {
  display: flex;
  width: 80%;
  color: white;
}

.home-container-2 > .row > .col {
  margin-top: 3rem;
}

.home-container-2 > .row > .col > .animated-box {
  background-color: #fff;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  text-align: center;
  margin: 10px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.home-container-2 > .row > .col > .animated-box > .animated-box:hover {
  transform: scale(1.1);
}

.home-container-2-animated-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  /* Additional styling for the container as needed */
}

.animate-left {
  animation: slideInLeft 1s forwards; /* Define the slide-in animation from the left */
}

.animate-right {
  animation: slideInRight 1s forwards; /* Define the slide-in animation from the right */
}

.home-container-2-animated-box {
  flex-direction: row-reverse;
}

.home-container-2-animated-box-one {
  width: 50%;
  flex-direction: column;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.months {
  &-local {
    &-picker {
      background-color: red;
      &.MuiPickersCalendarHeader-label
        .sscss-dplwbx-MuiPickersCalendarHeader-label {
        color: blue;
        background-color: black;
      }
    }
  }
}

.image-slider {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 70vh;
}

.slide {
  position: relative;
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.slide img {
  width: 50%;
  height: auto;
}

.slide-text {
  position: absolute;
  // top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8); /* Optional background color */
  // padding: 20px;
  border-radius: 10px;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}


@-webkit-keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}
@keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}
