.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.section {
  display: flex;
  flex-direction: column;
}



@media screen and (max-width: 769px) {
  .section{
    display: flex;
  flex-direction: row !important;
}
  
}
@media screen and (max-width: 600px) {
  .section{
    display: flex;
  flex-direction: row !important;
}
  
  
}