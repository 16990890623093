#cardbutton {
  position: relative;
  bottom: 200px !important;
  background-color: white !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 30px !important;
}

.button_logo {
  width: 50px;
}
.buttonlink {
  height: 300px;
  width: 350px;
  margin: 30px 10px !important;
  border-radius: 30px;
}
@media screen and (min-width: 768px) {
  .buttonlink {
    height: 300px;
    width: 350px;
    margin: 30px 10px !important;
    border-radius: 30px;
  }
}

@media screen and (max-width: 600px) {
  .buttonlink {
    height: 300px;
    width: 350px;
    margin: 30px 0px !important;
    border-radius: 30px;
  }
}

.homebuttoncontent {
  text-align: center;
  align-items: center;
}

.cardtext {
  font-weight: bold;
}

.buttonname {
  font-weight: bold;
}
.home_analysis_content {
  border: 1px solid #000;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
}
