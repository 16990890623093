:root {
  // colors
  --colors-primary: #011c5b;
  --colors-primary-hover: #042d8c;
  --colors-header: #012169;
  --colors-light-100: #f2f4f6;
  --colors-light-200: #e7e9ea;
  --colors-light-300: #cfd1d3;
  --colors-light-400: #babbbc;
  --colors-light-500: #9c9ea1;
  --colors-light-600: #86878b;

  --colors-dark-100: #181a1b;
  --colors-dark-200: #242527;
  --colors-dark-300: #37393a;
  --colors-dark-400: #424649;
  --colors-dark-500: #505154;
  --colors-dark-600: #6a6b6c;

  // utils
  --radius-xxs: 3px;
  --radius-xs: 6px;
  --radius-s: 8px;
  --radius-m: 12px;
  --radius-l: 16px;
  --radius-xl: 25px;
  --radius-xxl: 100%;
}

// colors
$header-color: #012169;
$button-border-color: #0d6efd;
$color-navy: white;
$equlion-color: #0d6efd;

// spacing
$gutter: 20px;

// typography
$default-font-size: 16px;

// utils
$finger-size: 60px;
$max-width: 1200px;

// durations
$animation-time: 0.5s;

// breakpoints
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;


