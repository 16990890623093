@use './variables' as *; 

@mixin size($width, $height) {
  height: $height;
  width: $width;
}

// Square Size Mixin: Yalnızca kare boyutları için
@mixin square-size($value) {
  @include size($value, $value);
}

// Border Mixin: Kenarlık boyutu, türü, rengi ve çapı
@mixin border($size: 0, $type: solid, $color: $equlion-color, $radius: 0) {
  border: 0;
  @if $size > 0 {
    border: $size $type $color;
  }
  @if $radius > 0 {
    border-radius: $radius;
  }
}

// Position Mixin: Konumlandırma için
@mixin position(
  $type: relative,  // Varsayılan olarak relative
  $top: auto,       // Varsayılan top değeri auto
  $bottom: auto,    // Varsayılan bottom değeri auto
  $left: auto,      // Varsayılan left değeri auto
  $right: auto      // Varsayılan right değeri auto
) {
  position: $type;
  @if $type == absolute {
    bottom: $bottom;
    left: $left;
    right: $right;
    top: $top;
  }
}
