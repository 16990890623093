@use '../variables';

.loan_container{
    width: 77%;
    margin-left: auto;
    margin-right: auto;
}

.page{
   display: flex;
   @media screen and (max-width: 600px) {
      flex-direction: column;
   }

   &_label{
    width: 500px;
    font-weight: 600;
    @media screen and (min-width: 768px){
      width: 250px;
    }
    @media screen and (max-width: 600px){
      width: 100%;
    }
 
   }

   &_table{
    &>input{
        width: 100% ;
        @media screen and (max-width: 600px){
            width: 100%;
          }
          @media screen and (min-width: 768px){
            width: 100%;
          }
    }

    &>textarea{
    padding: 3px;
    border-radius: var(--radius-s);
    border: 1px solid var(--colors-light-300);
    width: 100%;

    &:focus{
        outline-color: var(--colors-primary);
        border-color: transparent;
    }}
   }

   &_form_select{
    width: 20%;
    padding: 6px;
    color: var(--colors-dark-100);
    margin: 2px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: transparent;
    border-radius: var(--radius-s);
    border: 1px solid var(--colors-light-300);
    transition: 150ms outline-color ease, 150ms border-color ease;
    &:focus{
        outline-color: var(--colors-primary);
        border-color: transparent;
    }
    @media screen and (max-width: 600px){
      width: 100%;
      margin-top:10px;
    }
    @media screen and (max-width: 768px){
      width: 30%;
    }
    @media screen and (max-width: 820px){
      width: 30%;
    }
}

&_submit{
    width: 200px !important;
    margin:0 auto !important; 
}}


#get_loan{
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}

.check_input{
    padding:9px 9px 9px 0px !important;
}

.table_th{
    background: linear-gradient(256.41deg, #1184F2, #2555B9) !important;
    border:2px solid white;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    border-radius: 10px;
    color:white;
    max-width: 90px;
    min-width: 90px;
  

}
.table_div{
    text-align: center;
    font-size: 10px;
    max-width: 76px;
    min-width: 76px;
    border: 1px solid white;
    background-color: #DFDFDF;
    padding:2px;
    border-radius: 10px;
}


.table{
  @media screen and (max-width: 600px){
    display: flex;
    overflow-x:auto !important;

  }

  @media screen and (max-width: 768px){
    display: flex;
    overflow-x:auto !important;

  }

  @media screen and (max-width: 820px){
    display: flex;
    overflow-x:auto !important;

  }
  
 

  &>thead{
    @media screen and (max-width: 820px){
      padding-right: 50px;
    }
    &>tr{
    @media screen and (max-width: 820px){
      position: absolute;
      z-index: 1;
    }

    &>th{
      max-width: 90px;
      min-width: 90px;
    }

    @media screen and (max-width: 820px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      &>th{
        min-height: 220px;
        max-height: 220px;
        min-width: 0px;
        display: flex;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        justify-content: center;
        align-items: center;

        padding-inline: 30px 60px;
        writing-mode: vertical-rl;
        -moz-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
      
    }
  
    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
      justify-content: center;


      &>th{
        min-height: 220px;
        max-height: 220px;
        min-width: 0px;
        display: flex;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        justify-content: center;
        align-items: center;

        padding-inline: 30px 60px;
        writing-mode: vertical-rl;
        -moz-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        
      }
      
    }

    @media screen and (max-width: 600px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      &>th{
        min-height: 220px;
        max-height: 220px;
        min-width: 0px;
        display: flex;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        justify-content: center;
        align-items: center;

        padding-inline: 30px 60px;
        writing-mode: vertical-rl;
        -moz-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }

  }
}
  &>tbody>tr{
    &>td{
      &>ul>li{
        // padding-inline-start: none; 
        //  margin-block-end: none;

        display: block;
        padding-inline: 20px;
        margin-block-end: 2%;

      }
    }
    @media screen and (max-width: 600px){
      display: flex;
      flex-direction: column;
      text-align: center;

      &>td{
        min-height: 220px;
        max-height: 220px;
        min-width: 100px;
        max-width: 100px;
    

        &>ul>li{
          // padding-inline-start: none; 
          //  margin-block-end: none;

          display: block;
          padding-inline: 20px;
          margin-block-end: 2%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 120px;
        }

 

        display: flex;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
      text-align: center;

      &>td{
        min-height: 220px;
        max-height: 220px;
        min-width: 150px;
        max-width: 150px;
        margin-left:5px;
    

        &>ul>li{
          // padding-inline-start: none; 
          //  margin-block-end: none;

          display: block;
          padding-inline: 20px;
          margin-block-end: 2%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 150px;
        }

 

        display: flex;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      
    }
    @media screen and (max-width: 820px){
      display: flex;
      flex-direction: column;
      text-align: center;

      &>td{
        min-height: 220px;
        max-height: 220px;
        min-width: 150px;
        max-width: 150px;
        margin-left:5px;

        &>ul>li{
          // padding-inline-start: none; 
          //  margin-block-end: none;

          display: block;
          padding-inline: 20px;
          margin-block-end: 2%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 150px;
          list-style-type: disc !important;
        }

 

        display: flex;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      
    }
  }

}


.loan{
    display: flex;
    flex-direction: column;
    
    &_header{
        
        &_item{
        background: linear-gradient(256.41deg, #1184F2, #2555B9) !important;
        border:1px solid white !important;
        font-size: 12px;
        padding: 5px;
        border-radius: 10px;
        color:white;
        text-align: center;
    }}
  
    &_content{

        &_item{
          background:#DFDFDF !important ;
          border:1px solid white !important;
          border-radius: 10px;
          font-size: 10px;
          color:black;
          text-align: center;
        }
    }

}

#edit_and_delete{

  writing-mode: vertical-rl;
  background-color: white !important;

  @media screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
    }

    @media screen and (max-width: 820px){
      display: flex;
      flex-direction: column;
      
    }

    @media screen and (max-width: 600px){
      display: flex;
      flex-direction: column; 
    }
}

#loan_content_item_update{
    border-radius: 10px;
    border:1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;

    @media screen and (max-width: 768px){
      padding: 90px 22px;
      margin:0px;

    }

    @media screen and (max-width: 820px){
      padding: 90px 22px;
      margin:0px;

    }

    @media screen and (max-width: 600px){
      padding: 90px 22px;
      margin:0px;

    }
  }

  #loan_content_item_delete{

    border-radius: 10px;
    border:1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0px;


    @media screen and (max-width: 768px){
      padding: 90px 22px;
      margin:0px;
    }
    
    @media screen and (max-width: 820px){
      padding: 90px 22px;
      margin:0px;

    }

    @media screen and (max-width:600px){
      padding: 90px 22px;
      margin:0px;

    }
  }

  #loan_header_item_add_button{
    display: flex;
    justify-content: center ;
    align-items: center;
    border:1px solid white !important;
    font-size: 12px;
    border-radius: 10px;
    color:white;
    width: 100%;
    height: 50px;
    text-align: center;
  

    @media screen and (max-width: 768px){
      display: flex;
    justify-content: center ;
    align-items: center;
    border:1px solid white !important;
    font-size: 12px;
    border-radius: 10px;
    color:white;
    width: 100%;
    text-align: center;
    height: 50px;
    width: 50px;
    }

    @media screen and (max-width: 820px){
    display: flex;
    justify-content: center ;
    align-items: center;
    border:1px solid white !important;
    font-size: 12px;
    border-radius: 10px;
    color:white;
    width: 100%;
    text-align: center;
    height: 50px;
    width: 50px;
    }

  }


  #table_equity_table{
    margin:0 auto;
  }

  
.equity{
  display: flex;
  flex-direction: column;

  &_header{
      
      &_item{
      background: linear-gradient(256.41deg, #1184F2, #2555B9) !important;
      border:1px solid white !important;
      font-size: 12px;
      padding: 5px;
      border-radius: 10px;
      color:white;
      text-align: center;
      min-width: 90px;

      @media screen and (max-width:600px){
        font-size: 10px;
      }
  }

  }

  &_content{
      &_item{
        background:#DFDFDF !important ;
        border:1px solid white !important;
        border-radius: 10px;
        font-size: 10px;
        color:black;
        text-align: center;
      }
  }

  

}


#equity_header_item_add_button{
  display: flex;
  justify-content: center ;
  align-items: center;
  border:1px solid white !important;
  font-size: 12px;
  border-radius: 10px;
  color:white;
  height: 50px;
  min-width: 50px;
  text-align: center;
  min-width: 90px;

  @media screen and (max-width: 768px){
    display: flex;
  justify-content: center ;
  align-items: center;
  border:1px solid white !important;
  font-size: 12px;
  border-radius: 10px;
  color:white;
  width: 100%;
  text-align: center;
  height: 50px;
  min-width: 50px;
  }

  @media screen and (max-width: 820px){
  display: flex;
  justify-content: center ;
  align-items: center;
  border:1px solid white !important;
  font-size: 12px;
  border-radius: 10px;
  color:white;
  width: 100%;
  text-align: center;
  height: 50px;
  min-width: 50px;
  }

}



#equity_content_item_update{
  border-radius: 10px;
    border:1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0px;


    @media screen and (max-width: 768px){
      padding: 90px 22px;
      margin:0px;

    }

    @media screen and (max-width: 820px){
      padding: 90px 22px;
      margin:0px;

    }

    @media screen and (max-width: 600px){
      padding: 90px 22px;
      margin:0px;

    }
}

#equity_content_item_delete{
  border-radius: 10px;
    border:1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0px;


    @media screen and (max-width: 768px){
      padding: 90px 22px;
      margin:0px;

    }

    @media screen and (max-width: 820px){
      padding: 90px 22px;
      margin:0px;

    }

    @media screen and (max-width: 600px){
      padding: 90px 22px;
      margin:0px;

    }
}