@keyframes button-equlion-animation {
  from {
    top: 50%;
    width: 10em;
    height: 10em;
  }
  to {
    top: -3em;
    width: 6em;
    height: 6em;
  }
}

@keyframes login-fadein {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes login-border-animation {
  to {
    stroke-dasharray: 100%;
    stroke-dashoffset: 0;
  }
}

@keyframes logoAnimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}