// @import '../variables';
// .page {
//   display: flex;
//   flex-flow: row wrap;
//   align-items: flex-start;

//   gap: 1rem;
//   @media screen and (max-width: 820px) {
//     gap: 2rem;
//     flex-flow:row
//   }
//   @media screen and (max-width: 768px) {
//     flex-direction: row-reverse;
//     gap: 2rem;
//   }
//   @media screen and (max-width: 600px) {
//     flex-direction: column;
//     gap: 2rem;
//   }

//   & .row {
//     display: flex;
//   }

//   &_content {
//     flex: 1;
//     flex-direction: column;

//     inline-size: 100%;

//     gap: 1rem;

//     @media screen and (max-width: 768px) {
//       order: 0;
//     }

//     & .company_header {
//       & h1 {
//         letter-spacing: -0.05rem;
//         font-weight: 600;
//         color: var(--colors-dark-100);
//       }
//       & h6 {
//         margin-inline-start: .25rem;

//         letter-spacing: 0.025rem;
//         font-size: 14px;
//         color: var(--colors-light-600);
//       }
//     }

//     & .company {
//       display: flex;
//       flex-direction: column;

//       padding: 2rem 1.5rem 1rem;

//       border: 1px solid var(--colors-light-200);
//       border-radius: var(--radius-m);

//       gap: 1.5rem;

//       &_info {
//         display: flex;
//         flex-flow: row nowrap;
//         align-items: stretch;

//         gap: 1.25rem;

//         @media screen and (max-width: 992px) {
//           flex-direction: column;
//           gap: 2rem;
//         }

//         &_row {
//           position: relative;
  
//           display: flex;
//           flex-direction: column;
//           flex: 1;
  
//           border-radius: var(--radius-m);
//           background-color: var(--colors-light-100);
  
//           padding: 2.25rem 1.5rem 1.5rem;
  
//           &_title {
//             position: absolute;
//             top: 0; left: 0;
  
//             display: flex;
//             justify-content: center;
//             align-items: center;
  
//             block-size: 32px;
  
//             margin-block-start: -1rem;
//             margin-inline-start: .5rem;
//             padding: 0 1rem;
  
//             background-color: #fff;
//             border-radius: var(--radius-m);
//             border: 1px solid var(--colors-light-100);
            
//             font-size: 14px;
//             font-weight: 500;
//             color: var(--colors-dark-600);

//             @media screen and (max-width: 576px) {
//               & {
//                 font-size: 12px;
//               }
//             }
//           }
  
//           &_content_list {
//             display: flex;
//             flex-direction: column;
//             gap: 1rem;
  
//             & li {
//               display: flex;
//               flex-direction: row;
//               align-items: center;
//               flex: 1;
//               gap: .375rem;
  
//               background-color: var(--colors-light-100);
//               padding-bottom: 1rem;
//               justify-content: space-between;
  
//               &:not(:last-child) {
//                 border-bottom: 1px solid var(--colors-light-300);
//               }
  
//               & label {
//                 font-size: 16px;
//                 font-weight: 600;
//                 color: var(--colors-primary);
//               }
  
//               & p {
//                 font-weight: 400;
//                 color: var(--colors-dark-300);
//               }

//               & input {
//                 inline-size: 100%;
//                 max-width: 200px;
//                 min-width: 200px;
//               }
//             }
//           }
//         }
//       }

//       &_actions {
//         display: flex;
//         flex-flow: row nowrap;
//         justify-content: center;
//         align-items: stretch;
//         gap: .75rem;

//         & button[data-cancel] {
//           background-color: #e9eaeb;
//           color: #181a1b;
//         }

//         & button[data-update] {
//           inline-size: 162px;
//           block-size: 45px;
//         }
//       }
//     }
//   }

//   &_sidebar {
//     flex-direction: column;

//     border: 1px solid var(--colors-light-200);
//     border-radius: var(--radius-m);

//     padding: 1rem;
//     gap: 1rem;
//     &_edit_img{
//       width: 31.5px;
//       height: 31.5px;
//       margin-left:10px;
//     }
//     &_analysis_img{
//       width: 41px;
//       height: 31.5px;
//       margin-left:10px;
//     }
    // &_loan_img{
    //   width: 31.5px;
    //   height: 31.5px;
    //   margin-left:10px;
    // }
//     &_equity_img{
//       width: 21px;
//       height: 31.5px;
//       margin-left:10px;
//     }

//     @media screen and (max-width: 768px) {
//       inline-size: 100%;
//       order: 1;
//     }
//     @media screen and (max-width: 820px) {
//       flex-direction: row;
//       justify-content: center;
//       align-items: center;
//       &>a>button{
//       min-height: 50px;
      
//       }
//     }
//     @media screen and (max-width:600px) {
//       flex-direction: row;
//       flex-wrap: wrap;

//       &>a{
//       &>button{
//         font-size:14px;
//         min-height: 62px;
//         min-width: 130px;
//         max-width: 130px;
//       }}
//     }
//     @media screen and (max-width:820px) {
//       flex-direction: row;
//       flex-wrap: wrap;

//       &>a{
//       &>button{
//         font-size:14px;
  
//       }}
//     }    @media screen and (max-width:768px) {
//       flex-direction: row;
//       flex-wrap: wrap;

//       &>a{
//       &>button{
//         font-size:14px;
  
//       }}
//     }
//     & button {
//       position: relative;
//       flex: 1;
//       inline-size: 100%;

//       & .badge {
//         position: absolute;
//         top: -8px; right: -8px;

//         display: flex;
//         justify-content: center;
//         align-items: center;
        
//         block-size: 24px;
//         inline-size: 24px;
        
//         background-color: #f72c1e;
//         border-radius: var(--radius-xl);
        
//         font-size: 12px;
//         font-weight: 500;
//         color: #fff;
//       }
//     }
//   }
// }

// .my_firm_select{
//   height: 45px;
//   text-align: center;
//   font-weight: 600;
//   width: 160px;
//   &_options{
//     font-weight: 600;
//   }



// }

// .my_firm_analysis{
//   font-weight: 600;
//   font-size: 14px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }


// .header_acordion{
//  width: 100%;
//  display: flex;
//  align-items: center;
//  justify-content: space-around;
//  padding-bottom:10px;

//  @media screen and (max-width:600px) {
//   display: flex;
//   flex-wrap: wrap;
//   height: 150px;
//  }

// }


// .page_sidebar_common_img{
//   width: 36px;
//   width: 36px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }


.page_sidebar_loan_img{
  width: 30px;
  height: 30px;
}